import React, { useState } from "react";
// import img01 from "../../assets/img/images/about_img01.png";
import img02 from "../../assets/img/images/about_img02.png";
import logoImage from "../../../src/assets/img/logo/logo.png";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { t } from "i18next";

const WhoWeAre = (props) => {
  const [isShowReadMore, setisShowReadMore] = useState(false);

  return (
    <section id="about" className="about-area pt-130 pb-130">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
              <img src={logoImage} alt="" />
              <img src={img02} alt="" className="img-two" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <span className="sub-title">Who we are</span>
                <h2 className="title">
                  <Trans
                    i18nKey="who_we_are_title"
                    components={{ span: <span />, br: <br /> }}
                  />
                </h2>
              </div>
              <p>{t("who_we_are_description")}</p>
              <Link
                to="/"
                className="btn"
                onClick={() => setisShowReadMore(!isShowReadMore)}
              >
                {!isShowReadMore ? "Read More" : "Hide"}
              </Link>
            </div>
          </div>
        </div>
        {isShowReadMore && (
          <div
            className="section-title align-items-center justify-content-center d-flex flex-column text-justify mt-30"
            style={{ textAlign: "justify" }}
          >
            <span className="sub-title">{t("about_us")}</span>
            <div className="asd" style={{ fontSize: "2rem" }}>
              <p style={{ fontSize: "1.2rem", color: "#fff", opacity: 0.8 }}>
                {t("who_we_are_read_more_1")}
              </p>
              <p style={{ fontSize: "1.2rem", color: "#fff", opacity: 0.8 }}>
                {t("who_we_are_read_more_2")}
              </p>
              <p style={{ fontSize: "1.2rem", color: "#fff", opacity: 0.8 }}>
                {t("who_we_are_read_more_3")}
              </p>
              <p style={{ fontSize: "1.2rem", color: "#fff", opacity: 0.8 }}>
                {t("who_we_are_read_more_4")}
              </p>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default WhoWeAre;
