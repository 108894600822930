import React from "react";
import { useHorizontalScroll } from "../../lib/hooks/useHorizontalScroll";
import RoadmapItem from "./RoadmapItem";
import { t } from "i18next";
import { Trans } from "react-i18next";

const Roadmap = () => {
  const roadmap_items = [
    {
      roadmapTitle: "#1",
      title: t("our_service_list.item_1.title"),
      info: [t("our_service_list.item_1.desc")],
    },
    {
      roadmapTitle: "#2",
      title: t("our_service_list.item_2.title"),
      info: [t("our_service_list.item_2.desc")],
    },
    {
      roadmapTitle: "#3",
      title: t("our_service_list.item_3.title"),
      info: [t("our_service_list.item_3.desc")],
    },
    {
      roadmapTitle: "#4",
      title: t("our_service_list.item_4.title"),
      info: [t("our_service_list.item_4.desc")],
    },
    {
      roadmapTitle: "#5",
      title: t("our_service_list.item_5.title"),
      info: [t("our_service_list.item_5.desc")],
    },
    {
      roadmapTitle: "#6",
      title: t("our_service_list.item_6.title"),
      info: [t("our_service_list.item_6.desc")],
    },
    {
      roadmapTitle: "#7",
      title: t("our_service_list.item_7.title"),
      info: [t("our_service_list.item_7.desc")],
    },
    {
      roadmapTitle: "#8",
      title: t("our_service_list.item_8.title"),
      info: [t("our_service_list.item_8.desc")],
    },
    {
      roadmapTitle: "#9",
      title: t("our_service_list.item_9.title"),
      info: [t("our_service_list.item_9.desc")],
    },
    {
      roadmapTitle: "#10",
      title: t("our_service_list.item_10.title"),
      info: [t("our_service_list.item_10.desc")],
    },
  ];

  const scrollRef = useHorizontalScroll();

  return (
    <section id="our-service" className="roadmap-area pt-130 pb-130">
      <div className="container custom-container-two">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-8">
            <div className="section-title text-center mb-60">
              <span className="sub-title">{t("our_service")}</span>
              <h2 className="title">
                <Trans
                  i18nKey="our_service_title"
                  components={{ span: <span /> }}
                />
              </h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div
              className="bt-roadmap_x bt-roadmap-scrollbar"
              ref={scrollRef}
              style={{ overflowX: "auto", overflowY: "hidden" }}
            >
              <div className="bt-roadmap-wrap" style={{ gap: "1%" }}>
                {roadmap_items.map((x, index) => (
                  <RoadmapItem key={index} item={x} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
