import $ from "jquery";

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const handleClickScroll = (id) => {
  console.log(id);
  const element = document.getElementById(id);
  if (element) {
    // 👇 Will scroll smoothly to the top of the next section
    element.scrollIntoView({ behavior: "smooth" });
  }
  handleClickBurgerIconMobile(false);
};

export const handleClickBurgerIconMobile = (isShowMobileSidebar) => {
  console.log(isShowMobileSidebar);
  if (isShowMobileSidebar) {
    $("body").addClass("mobile-menu-visible");
  } else {
    $("body").removeClass("mobile-menu-visible");
  }
};
