import React, { createRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import style from "./ContactOneForm.module.css";
import { t } from "i18next";
import emailjs from "@emailjs/browser";

const ContactOneForm = () => {
  const recaptchaRef = createRef();
  const [capthcaError, setcapthcaError] = useState("");
  const [fromName, setfromName] = useState("");
  const [fromEmail, setfromEmail] = useState("");
  const [fromMessage, setfromMessage] = useState("");
  const [isSuccess, setisSuccess] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    setisLoading(true);
    const recaptchaValue = recaptchaRef.current.getValue();

    if (!recaptchaValue) {
      setcapthcaError("Captcha required!");
    } else {
      setcapthcaError("");
      recaptchaRef.current.reset();
      await emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          {
            from_name: fromName,
            from_email: fromEmail,
            message: fromMessage,
          },
          process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        )
        .then(
          (result) => {
            setisLoading(false);
            if (result.status === 200) {
              setfromEmail("");
              setfromName("");
              setfromMessage("");
              setisSuccess(true);
              setTimeout(() => {
                setisSuccess(false);
              }, 5000);
            }
          },
          (error) => {
            setisLoading(false);
            console.log("error", error);
          }
        );
    }
  };

  const handleCaptchaChange = (value) => {
    if (value) {
      setcapthcaError("");
    }
  };

  return (
    <div className="contact-form-wrap">
      <form onSubmit={onSubmit}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-grp">
              <input
                type="text"
                placeholder={t("contact_form.name_placeholder")}
                onChange={(e) => setfromName(e.currentTarget.value)}
                value={fromName}
                required
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-grp">
              <input
                type="email"
                placeholder={t("contact_form.email_placeholder")}
                onChange={(e) => setfromEmail(e.currentTarget.value)}
                value={fromEmail}
                required
              />
            </div>
          </div>
        </div>
        <div className="form-grp">
          <textarea
            name="massage"
            placeholder={t("contact_form.message_placeholder")}
            onChange={(e) => setfromMessage(e.currentTarget.value)}
            value={fromMessage}
            required
          ></textarea>
        </div>
        <div className={`${style.recaptcha}`}>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onChange={handleCaptchaChange}
          />
          {capthcaError && (
            <div className={style.recaptchaError}>{capthcaError}</div>
          )}
          {isSuccess && (
            <div className={style.successForm}>
              Email already been sent! We will inform you as soon as possible.
            </div>
          )}
        </div>
        <div className="submit-btn text-center">
          <button type="submit" className="btn" disabled={isLoading}>
            Send Massage
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactOneForm;
