import React from "react";
import shape01 from "../../assets/img/banner/banner_shape01.png";
import shape02 from "../../assets/img/banner/banner_shape02.png";
import shape03 from "../../assets/img/banner/banner_shape03.png";
import { t } from "i18next";
import { Trans } from "react-i18next";

const Banner = (props) => {
  return (
    <section className="banner-area banner-bg">
      <div className="banner-shape-wrap">
        <img src={shape01} alt="" className="img-one" />
        <img src={shape02} alt="" className="img-two" />
        <img src={shape03} alt="" className="img-three" />
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="banner-content text-center">
              {/* <img src={fireIcon} alt="" /> */}
              <h2 className="title">
                <Trans i18nKey="hero_title" components={{ span: <span /> }} />
              </h2>
              <p style={{ fontSize: 24 }}>{t("hero_description_1")}</p>
              <p style={{ fontSize: 24 }}>{t("hero_description_2")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
